<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
        <a-form-item label="标题">
          <a-input
            v-decorator="[
              'title',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item label="分类">
          <a-radio-group
            v-decorator="[
              'category',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
          >
            <a-radio
              v-for="item in categoryList"
              :key="item.value"
              :value="item.value"
            >{{ item.name }}</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="封面图">
          <FileUpload
            uploadType="image"
            :multiple="false"
            @uploaded="uploaded"
            :imagePath="imagePath"
          />
        </a-form-item>

        <a-form-item label="置顶值">
          <a-input-number placeholder="置顶值越高，排名越靠前" style="width: 100%" v-decorator="['top']" />
        </a-form-item>

        <a-form-item label="发布时间">
          <a-date-picker
            placeholder
            v-decorator="[
              'publishAt',
              {
                initialValue: moment(),
                rules: [{ required: true, message: '请选择！' }],
              },
            ]"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="摘要">
          <a-textarea
            :auto-size="{ minRows: 3 }"
            v-decorator="[
              'brief',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item>
          <span class="required" slot="label">正文</span>
          <div id="editor"></div>
        </a-form-item>

        <a-form-item label="展示">
          <a-radio-group
            v-decorator="[
              'publishType',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
          >
            <a-radio
              v-for="item in [{
                name: '内部',
                value: 'internal',
              }, {
                name: '外部',
                value: 'external',
              }, {
                name: '全部',
                value: 'both',
              }]"
              :key="item.value"
              :value="item.value"
            >{{ item.name }}</a-radio>
          </a-radio-group>
        </a-form-item>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading="saveLoading">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import E from "wangeditor";
import FileUpload from "@/components/file-upload";
import moment from "moment";
import { add } from "@/api/website/news";
import { categoryList } from "./data";
export default {
  name: "websiteNewsAdd",

  components: {
    FileUpload
  },

  data() {
    return {
      form: this.$form.createForm(this),
      categoryList,

      editor: null,
      imagePath: "",

      saveLoading: false
    };
  },

  mounted() {
    const editor = new E("#editor");
    const that = this;

    editor.config.height = 600;
    editor.config.zIndex = 1;
    editor.config.placeholder = "";
    editor.config.uploadImgServer = "/api/common-service/file/upload";
    editor.config.uploadImgHeaders = {
      token: window.localStorage.getItem("token")
    };
    editor.config.uploadFileName = "files";
    editor.config.uploadImgHooks = {
      customInsert: function(insertImgFn, result) {
        if (result && Array.isArray(result.data) && result.data.length > 0) {
          insertImgFn(result.data[0].completePath);
        }
      }
    };

    editor.config.onchange = function() {
      const content = editor.txt.text();
      if (content) {
        that.form.setFieldsValue({
          brief: content.substring(0, 175)
        });
      }
    };
    editor.config.onchangeTimeout = 500; // 修改为 500ms

    editor.create();
    this.editor = editor;
  },

  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  },

  methods: {
    moment() {
      return moment();
    },

    uploaded(res) {
      if (res.length > 0) {
        this.imagePath = res[0].completePath;
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      const content = this.editor.txt.html();

      if (content.length === 0) {
        this.$message.error("请输入正文！");
        return;
      }

      if (!this.imagePath) {
        let patt = /http(s)?:\/\/(\S|\s)+\.(png|jpg|jpeg|webp)/;
        const result = content.match(patt);
        if (result && result.length > 0) {
          this.imagePath = result[0];
        } else {
          this.$message.error("请上传封面图");
          return;
        }
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.saveLoading = true;

          add({
            ...values,
            content,
            face: this.imagePath,
            isShow: 1,
            status: 1,
            publishAt: values.publishAt.format("YYYY-MM-DD HH:mm:ss")
          })
            .then(() => {
              this.$close(this.$route.path);
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 30px 164px;
}

.center {
  margin-top: 64px;
}
</style>
