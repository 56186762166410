var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 12 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'title',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'title',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"分类"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'category',
            { rules: [{ required: true, message: '请选择！' }] },
          ]),expression:"[\n            'category',\n            { rules: [{ required: true, message: '请选择！' }] },\n          ]"}]},_vm._l((_vm.categoryList),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-item',{attrs:{"label":"封面图"}},[_c('FileUpload',{attrs:{"uploadType":"image","multiple":false,"imagePath":_vm.imagePath},on:{"uploaded":_vm.uploaded}})],1),_c('a-form-item',{attrs:{"label":"置顶值"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['top']),expression:"['top']"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"置顶值越高，排名越靠前"}})],1),_c('a-form-item',{attrs:{"label":"发布时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'publishAt',
            {
              initialValue: _vm.moment(),
              rules: [{ required: true, message: '请选择！' }],
            },
          ]),expression:"[\n            'publishAt',\n            {\n              initialValue: moment(),\n              rules: [{ required: true, message: '请选择！' }],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"摘要"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'brief',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'brief',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1),_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("正文")]),_c('div',{attrs:{"id":"editor"}})]),_c('a-form-item',{attrs:{"label":"展示"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'publishType',
            { rules: [{ required: true, message: '请选择！' }] },
          ]),expression:"[\n            'publishType',\n            { rules: [{ required: true, message: '请选择！' }] },\n          ]"}]},_vm._l(([{
              name: '内部',
              value: 'internal',
            }, {
              name: '外部',
              value: 'external',
            }, {
              name: '全部',
              value: 'both',
            }]),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.saveLoading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }